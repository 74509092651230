/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../scss/app.scss');

const $ = require('jquery');

require('bootstrap');
require("jquery-ui");
require("jquery-ui/ui/widgets/datepicker");
require("jquery-ui/ui/widgets/autocomplete");
require("jquery-ui/ui/widgets/sortable");
require("jquery-ui/ui/widgets/accordion");
require("jquery-ui/ui/widgets/draggable");
require("jquery-ui/ui/widgets/droppable");

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything


// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');
window.jQuery = $;
window.$ = $;

window.addEventListener('load', function() {
    $('[data-toggle="popover"]').popover();
    $('.toast').toast({'autohide':false});
    $('.toast').toast('show');
});

import Vue from "vue";
import Dropdown from "./VueComponents/Dropdown";
import NavigationToggleButton from "./VueComponents/NavigationToggleButton";
import MegaMenuModal from "./VueComponents/MegaMenu/MegaMenuModal";
import MegaMenuTrigger from "./VueComponents/MegaMenu/MegaMenuTrigger";

new Vue({
  el: "#app",
  components: {
    Dropdown,
    NavigationToggleButton,
    MegaMenuModal,
    MegaMenuTrigger
  }
});