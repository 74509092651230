<template>
  <div class="c-dropdown">
    <button
      class="c-dropdown--trigger btn"
      type="button"
      :id="`dropdown-${ _uid }`"
      aria-haspopup="listbox"
      :aria-expanded="showList ? 'true' : 'false'"
      ref="trigger"
    >
      <slot name="trigger">Dropdown label</slot>
      <svg class="c-dropdown--icon" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 12 12"><g class="nc-icon-wrapper" fill="currentColor"><path d="M11.5,2H.5a.5.5,0,0,0-.412.783l5.5,8a.5.5,0,0,0,.824,0l5.5-8A.5.5,0,0,0,11.5,2Z" fill="currentColor"/></g></svg>
    </button>

    <ul
      class="c-dropdown--list"
      ref="list"
      role="listbox"
      v-show="showList"
    >
      <slot name="list">
        <li class="c-dropdown--list-item">Empty</li>
      </slot>
    </ul>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";

export default {
  data() {
    return {
      showList: false,
      popper: {},
    }
  },

  watch: {
    showList() {
      if (this.showList) {
        this.popper.update();
      }
    }
  },

  methods: {
    clickedOutside(e) {
      this.showList = this.$el.contains(e.target) ? !this.showList : false;
    },
  },

  mounted() {
    document.addEventListener('click', this.clickedOutside);

    this.$nextTick(() => {
      this.popper = createPopper(this.$refs.trigger, this.$refs.list, {
        placement: 'bottom-start',
        modifiers: [
          {
            name: "sameWidth",
            enabled: true,
            phase: "beforeWrite",
            requires: ["computeStyles"],
            fn: ({ state }) => {
              state.styles.popper.minWidth = `${state.rects.reference.width}px`;
            },
            effect: ({ state }) => {
              state.elements.popper.style.minWidth = `${
                state.elements.reference.offsetWidth
              }px`;
            }
          }
        ]
      });
    });
  },

    destroyed() {
    document.removeEventListener('click', this.clickedOutside);
  },

  deactivated() {
    document.removeEventListener('click', this.clickedOutside);
  }
}
</script>

<style lang="scss" scoped>
  .c-dropdown {
    a {
      text-decoration: none;
    }

    &--trigger {
      border: none;
      display: flex;
      align-items: center;
    }

    &--icon {
      margin-left: 0.5rem;
    }

    &--list {
      list-style-type: none;
      background: white;
      z-index: 99999;
      padding: 0.5rem 0;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
      max-width: 20rem;
      max-height: 70vh;
      overflow: auto;
      border: 1px solid rgba(0, 0, 0, 0.15);

      & .c-dropdown--list-item {
        display: block;
        padding: 0.25rem 1.5rem;
        font-size: 0.9rem;
        text-decoration: none;

        &:hover {
          cursor: pointer;
          color: #16181b;
          background-color: #f8f9fc;
        }
      }
    }

    &--trigger, &--list {
      // If these have margins, popper complains
      margin: 0;
    }
  }
</style>
