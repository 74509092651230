<template>
  <section v-if="!isEmpty(parent)">
    <a :href="parent.href" class="title parent-label">
      <span>{{ parent.V }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><title>frame-c-arrow-right</title><g stroke-width="1" fill="#111111" stroke="#111111"><polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="6.5,4.5 10,8 6.5,11.5 "></polyline> <circle fill="none" stroke="#111111" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="8" cy="8" r="7.5"></circle></g></svg>
    </a>
    <ul class="siblings-list">
      <template v-for="(sibling, index) in parent.siblings" >
        <a
          v-if="sibling.V !== 'null'"
          :key="sibling._id"
          :href="sibling.href"
          :data-id="sibling._id"
          @click="$emit('input', sibling)"
          :title="sibling.V"
        >
          {{ sibling.V }}
        </a>

        <template v-else>
          <hr :key="index">
        </template>
      </template>
    </ul>
  </section>
</template>

<script>
import { isEmpty } from '../../../utils/isEmpty'

export default {
  data () {
    return {
      isEmpty,
    }
  },

  props: {
    parent: {
      type: [Object, null],
    },
  },
}
</script>

<style lang="scss" scoped>
  .parent-label {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    display: grid;
    grid-template-columns: 1fr auto;

    align-items: center;
    text-decoration: none;
    color: inherit;
  }

  a {
    display: block;
  }

  hr {
    border: transparent;
    border-top: 1px solid #d7d7d7;
    margin: 0;
  }
</style>
