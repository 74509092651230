<template>
  <button
    id="mega-menu-trigger"
    @click="openModal"
  >
    <slot>
      Navigation
    </slot>
  </button>
</template>

<script>
import EventBus from '../../utils/EventBus'

export default {
  methods: {
    openModal () {
      EventBus.$emit('openMegaMenu')
    },
  },
}
</script>
