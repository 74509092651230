<template>
  <a href="#" @click.prevent="toggleNavigation" role="button">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g class="nc-icon-wrapper"><path data-color="color-2" d="M15,7H1C0.4,7,0,7.4,0,8s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,7,15,7z"/> <path d="M15,1H1C0.4,1,0,1.4,0,2s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,1,15,1z"/> <path d="M15,13H1c-0.6,0-1,0.4-1,1s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,13,15,13z"/></g></svg>
  </a>
</template>

<script>
export default {
  methods: {
    data() {
      return {
        sidebarElement: null
      }
    },

    toggleNavigation() {
      this.sidebarElement.classList.toggle('show');
    },

    checkIfClickedOutside(event) {
      if (this.$el.contains(event.target)) {
        return;
      }

      let clickedOutside = !this.sidebarElement.contains(event.target);

      if(clickedOutside && this.sidebarElement.classList.contains('show')) {
        event.preventDefault();
        this.toggleNavigation();
      }
    }
  },

  mounted() {
    this.sidebarElement = document.querySelector('aside');

    document.addEventListener('click', this.checkIfClickedOutside);
  },

  destroyed() {
    document.removeEventListener('click', this.checkIfClickedOutside);
  },

  deactivated() {
    document.removeEventListener('click', this.checkIfClickedOutside);
  }
}
</script>

<style lang="scss" scoped>
  button {
    display: block;
    border: none;
    text-align: left;

    svg {
      fill: currentColor;
    }
  }
</style>
