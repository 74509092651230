<template>
  <section>
    <p class="title self-title">{{ self.V }}</p>
    <div class="lists">
      <ul class="main-link-list">
        <li
          v-for="(collection, index) in mainCollectionList"
          :key="index"
          :data-collection-title="collection.V"
          :title="collection.V"
          @click="select(collection)"
          @mouseover="select(collection)"
          :class="value !== undefined && value._id === collection._id ? 'active' : null"
        >
          <span>{{ collection.V }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><title>frame-c-arrow-right</title><g stroke-width="1" fill="#111111" stroke="#111111"><polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="6.5,4.5 10,8 6.5,11.5 "></polyline> <circle fill="none" stroke="#111111" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="8" cy="8" r="7.5"></circle></g></svg>
        </li>
      </ul>

      <ul class="static-links">
        <li
          v-for="(collection, index) in staticLinksCollections"
          :key="index"
          :data-collection-title="collection.V"
          :title="collection.V"
          @click="select(collection)"
          @mouseover="select(collection)"
          :class="value !== undefined && value._id === collection._id ? 'active' : null"
        >
          <span>{{ collection.V }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><title>frame-c-arrow-right</title><g stroke-width="1" fill="#111111" stroke="#111111"><polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="6.5,4.5 10,8 6.5,11.5 "></polyline> <circle fill="none" stroke="#111111" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" cx="8" cy="8" r="7.5"></circle></g></svg>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { isEmpty } from '../../../utils/isEmpty.js'
import { filterOutEmptyCollections } from '../../../utils/filterOutEmptyCollections.js'
import { removeStaticCollections } from '../../../utils/removeStaticCollections.js'
import { getStaticCollections } from '../../../utils/getStaticCollections.js'

export default {
  props: {
    self: {
      type: Object,
    },

    value: {
      type: Object,
      required: false,
    },
  },

  computed: {
    mainCollectionList () {
      if (isEmpty(this.self.collections))
        return

      const collections = filterOutEmptyCollections(this.self.collections)

      return removeStaticCollections(collections)
    },

    staticLinksCollections () {
      return this.self === undefined ? [] : getStaticCollections(this.self)
    },
  },

  methods: {
    select (collection) {
      this.$emit('input', collection)
    },
  },
}
</script>

<style lang="scss" scoped>
  section {
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .lists {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .static-links {
    margin-top: 2rem;
    border-top: 1px solid #d7d7d7;
  }

  .self-title {
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  li {
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    grid-gap: 0.5rem;

    span {
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    svg {
      display: none;
    }

    &.active svg,
    &:hover svg {
      display: block;
    }
  }
</style>
