import staticCollectionIdentifiers from './staticCollectionIdentifiers'
import { isEmpty } from './isEmpty'

export function removeStaticCollections (collections) {
  if (isEmpty(collections))
    return

  return collections.filter(collection => {
    let shouldIncludeInFilteredArray = true

    staticCollectionIdentifiers.forEach(matcher => {
      if (collection._id.includes(matcher))
        shouldIncludeInFilteredArray = false
    })

    return shouldIncludeInFilteredArray
  })
}
