var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isEmpty(_vm.parent)
    ? _c("section", [
        _c(
          "a",
          {
            staticClass: "title parent-label",
            attrs: { href: _vm.parent.href }
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.parent.V))]),
            _vm._v(" "),
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: "16",
                  height: "16",
                  viewBox: "0 0 16 16"
                }
              },
              [
                _c("title", [_vm._v("frame-c-arrow-right")]),
                _c(
                  "g",
                  {
                    attrs: {
                      "stroke-width": "1",
                      fill: "#111111",
                      stroke: "#111111"
                    }
                  },
                  [
                    _c("polyline", {
                      attrs: {
                        fill: "none",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-miterlimit": "10",
                        points: "6.5,4.5 10,8 6.5,11.5 "
                      }
                    }),
                    _vm._v(" "),
                    _c("circle", {
                      attrs: {
                        fill: "none",
                        stroke: "#111111",
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-miterlimit": "10",
                        cx: "8",
                        cy: "8",
                        r: "7.5"
                      }
                    })
                  ]
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "siblings-list" },
          [
            _vm._l(_vm.parent.siblings, function(sibling, index) {
              return [
                sibling.V !== "null"
                  ? _c(
                      "a",
                      {
                        key: sibling._id,
                        attrs: {
                          href: sibling.href,
                          "data-id": sibling._id,
                          title: sibling.V
                        },
                        on: {
                          click: function($event) {
                            return _vm.$emit("input", sibling)
                          }
                        }
                      },
                      [_vm._v("\n        " + _vm._s(sibling.V) + "\n      ")]
                    )
                  : [_c("hr", { key: index })]
              ]
            })
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }