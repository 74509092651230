export function isEmpty (item) {
  if (item === undefined)
    return true

  if (item === null)
    return true

  if (item === '')
    return true

  if (item.length !== undefined)
    return item.length === 0

  if (typeof item === 'object')
    return Object.keys(item).length === 0 || item.V === null

  return false
}
