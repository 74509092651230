var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _vm.linkList !== null && _vm.linkList !== undefined
      ? _c(
          "ul",
          _vm._l(_vm.linkList, function(link, index) {
            return _c("li", { key: index }, [
              _c("a", { attrs: { href: link.href, title: link.V } }, [
                _vm._v(_vm._s(link.V))
              ])
            ])
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }