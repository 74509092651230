<template>
  <div class="mega-menu" v-show="show">
    <div class="modal-backdrop" @click="close">
      <div class="menu" @click.stop>
        <upper-nodes
          :parent="menu.parent"
          @input="redirect"
        />
        <self
          :self="menu.self"
          :value="selectedCollection"
          @input="setResults"
        />
        <result-nodes :link-list="menu.results"/>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../../utils/EventBus'
import UpperNodes from './partials/UpperNodes.vue'
import Self from './partials/Self.vue'
import ResultNodes from './partials/ResultNodes.vue'
import { createPopper } from '@popperjs/core'
import { isEmpty } from '../../utils/isEmpty'

export default {
  name: 'MegaMenuModal',

  props: {
    dataSet: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      show: false,
      selectedCollection: {},
      menu: {
        self: {},
        parent: {},
        results: [],
      },

      popper: {},
      trigger: null,
    }
  },

  components: {
    UpperNodes,
    Self,
    ResultNodes,
  },

  methods: {
    redirect (node) {
      window.location.href = node.href
    },

    setResults (collection) {
      if (collection.docs === undefined) {
        this.menu.results = null
        return
      }

      this.selectedCollection = collection

      this.menu.results = collection.docs
    },

    open () {
      this.show = true
    },

    close () {
      this.show = false
    },

    handleEscape (e) {
      if (e.key === 'Escape')
        this.close()
    },

    positionNextToTrigger () {
      if (!isEmpty(this.popper)) {
        this.popper.update()
        return
      }

      const trigger = document.querySelector('#mega-menu-trigger')
      const modalBody = this.$el.querySelector('.menu')

      this.popper = createPopper(trigger, modalBody, {
        placement: 'right-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 16],
            },
          },
        ],
      })
    },

    decideIfModalShouldStickToTrigger () {
      if (isEmpty(this.trigger))
        return

      const treshold = this.trigger.offsetWidth + 960

      if (window.innerWidth > treshold)
        this.positionNextToTrigger()
      else {
        if (!isEmpty(this.popper))
          this.popper.destroy()
        this.popper = {}
      }
    },
  },

  mounted () {
    this.menu.self = this.dataSet.self
    this.menu.parent = this.dataSet.parent

    document.addEventListener('keydown', this.handleEscape)
    EventBus.$on('openMegaMenu', this.open)

    this.trigger = document.querySelector('#mega-menu-trigger')

    window.addEventListener('resize', this.decideIfModalShouldStickToTrigger)
    this.decideIfModalShouldStickToTrigger()
  },

  destroyed () {
    document.removeEventListener('keydown', this.handleEscape)
    EventBus.$off('openMegaMenu')
    window.removeEventListener('resize', this.decideIfModalShouldStickToTrigger)
  },
}
</script>

<style lang="scss">
.mega-menu {
  .modal-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999999;
    background: rgba(0,0,0,.08);
    width: 100vw;
    height: 100vh;
  }

  .menu {
    position: fixed;
    left: 50%;
    top: 2rem;
    transform: translate(-50%, 0);
    width: 90%;
    max-width: 960px;
    background: white;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-template-rows: 1fr;
    line-height: 2;
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    overflow-x: auto;

    box-shadow: 0 15px 30px 0 rgba(0,0,0,.11), 0 5px 15px 0 rgba(0,0,0,.08);

    max-height: calc(100vh - 4rem);

    section {
      overflow-y: auto;
      min-width: 0;
      padding: 0.5rem 0;

      .title {
        padding: 0.25rem 1rem;
      }
    }

    ul {
      list-style-type: none;
      padding: 0;

      li, a {
        padding: 0.25rem 1rem;
        color: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;

        &:hover {
          background: #e9edf5;
        }
      }
    }
  }
}
</style>
