<template>
  <section>
    <ul v-if="linkList !== null && linkList !== undefined">
      <li v-for="(link, index) in linkList" :key="index">
        <a
          :href="link.href"
          :title="link.V"
        >{{ link.V }}</a>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  props: {
    linkList: {
      type: [Array, null],
    },
  },
}
</script>
