import staticCollectionIdentifiers from './staticCollectionIdentifiers'
import { isEmpty } from './isEmpty'

export function getStaticCollections (self) {
  if (isEmpty(self.collections))
    return

  const collections = []

  staticCollectionIdentifiers.forEach(matcher => {
    const foundCollection = self.collections.find(collection => collection._id.includes(matcher))

    if (foundCollection !== undefined)
      collections.push(foundCollection)
  })

  return collections
}
