var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      attrs: { href: "#", role: "button" },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.toggleNavigation($event)
        }
      }
    },
    [
      _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            width: "16",
            height: "16",
            viewBox: "0 0 16 16"
          }
        },
        [
          _c("g", { staticClass: "nc-icon-wrapper" }, [
            _c("path", {
              attrs: {
                "data-color": "color-2",
                d:
                  "M15,7H1C0.4,7,0,7.4,0,8s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,7,15,7z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M15,1H1C0.4,1,0,1.4,0,2s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,1,15,1z"
              }
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                d:
                  "M15,13H1c-0.6,0-1,0.4-1,1s0.4,1,1,1h14c0.6,0,1-0.4,1-1S15.6,13,15,13z"
              }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }