var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    { attrs: { id: "mega-menu-trigger" }, on: { click: _vm.openModal } },
    [_vm._t("default", [_vm._v("\n    Navigation\n  ")])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }