var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("p", { staticClass: "title self-title" }, [_vm._v(_vm._s(_vm.self.V))]),
    _vm._v(" "),
    _c("div", { staticClass: "lists" }, [
      _c(
        "ul",
        { staticClass: "main-link-list" },
        _vm._l(_vm.mainCollectionList, function(collection, index) {
          return _c(
            "li",
            {
              key: index,
              class:
                _vm.value !== undefined && _vm.value._id === collection._id
                  ? "active"
                  : null,
              attrs: {
                "data-collection-title": collection.V,
                title: collection.V
              },
              on: {
                click: function($event) {
                  return _vm.select(collection)
                },
                mouseover: function($event) {
                  return _vm.select(collection)
                }
              }
            },
            [
              _c("span", [_vm._v(_vm._s(collection.V))]),
              _vm._v(" "),
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "16",
                    height: "16",
                    viewBox: "0 0 16 16"
                  }
                },
                [
                  _c("title", [_vm._v("frame-c-arrow-right")]),
                  _c(
                    "g",
                    {
                      attrs: {
                        "stroke-width": "1",
                        fill: "#111111",
                        stroke: "#111111"
                      }
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-miterlimit": "10",
                          points: "6.5,4.5 10,8 6.5,11.5 "
                        }
                      }),
                      _vm._v(" "),
                      _c("circle", {
                        attrs: {
                          fill: "none",
                          stroke: "#111111",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-miterlimit": "10",
                          cx: "8",
                          cy: "8",
                          r: "7.5"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "static-links" },
        _vm._l(_vm.staticLinksCollections, function(collection, index) {
          return _c(
            "li",
            {
              key: index,
              class:
                _vm.value !== undefined && _vm.value._id === collection._id
                  ? "active"
                  : null,
              attrs: {
                "data-collection-title": collection.V,
                title: collection.V
              },
              on: {
                click: function($event) {
                  return _vm.select(collection)
                },
                mouseover: function($event) {
                  return _vm.select(collection)
                }
              }
            },
            [
              _c("span", [_vm._v(_vm._s(collection.V))]),
              _vm._v(" "),
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "16",
                    height: "16",
                    viewBox: "0 0 16 16"
                  }
                },
                [
                  _c("title", [_vm._v("frame-c-arrow-right")]),
                  _c(
                    "g",
                    {
                      attrs: {
                        "stroke-width": "1",
                        fill: "#111111",
                        stroke: "#111111"
                      }
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-miterlimit": "10",
                          points: "6.5,4.5 10,8 6.5,11.5 "
                        }
                      }),
                      _vm._v(" "),
                      _c("circle", {
                        attrs: {
                          fill: "none",
                          stroke: "#111111",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "stroke-miterlimit": "10",
                          cx: "8",
                          cy: "8",
                          r: "7.5"
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }