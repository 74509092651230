var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "c-dropdown" }, [
    _c(
      "button",
      {
        ref: "trigger",
        staticClass: "c-dropdown--trigger btn",
        attrs: {
          type: "button",
          id: "dropdown-" + _vm._uid,
          "aria-haspopup": "listbox",
          "aria-expanded": _vm.showList ? "true" : "false"
        }
      },
      [
        _vm._t("trigger", [_vm._v("Dropdown label")]),
        _vm._v(" "),
        _c(
          "svg",
          {
            staticClass: "c-dropdown--icon",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "8",
              height: "8",
              viewBox: "0 0 12 12"
            }
          },
          [
            _c(
              "g",
              {
                staticClass: "nc-icon-wrapper",
                attrs: { fill: "currentColor" }
              },
              [
                _c("path", {
                  attrs: {
                    d:
                      "M11.5,2H.5a.5.5,0,0,0-.412.783l5.5,8a.5.5,0,0,0,.824,0l5.5-8A.5.5,0,0,0,11.5,2Z",
                    fill: "currentColor"
                  }
                })
              ]
            )
          ]
        )
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showList,
            expression: "showList"
          }
        ],
        ref: "list",
        staticClass: "c-dropdown--list",
        attrs: { role: "listbox" }
      },
      [
        _vm._t("list", [
          _c("li", { staticClass: "c-dropdown--list-item" }, [_vm._v("Empty")])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }