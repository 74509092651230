var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.show, expression: "show" }
      ],
      staticClass: "mega-menu"
    },
    [
      _c("div", { staticClass: "modal-backdrop", on: { click: _vm.close } }, [
        _c(
          "div",
          {
            staticClass: "menu",
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [
            _c("upper-nodes", {
              attrs: { parent: _vm.menu.parent },
              on: { input: _vm.redirect }
            }),
            _vm._v(" "),
            _c("self", {
              attrs: { self: _vm.menu.self, value: _vm.selectedCollection },
              on: { input: _vm.setResults }
            }),
            _vm._v(" "),
            _c("result-nodes", { attrs: { "link-list": _vm.menu.results } })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }